
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.navbar-brand {
    height: 60px;
    padding: 5px 15px;
    img {
        height: 100%;
    }
}
.navbar-nav > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
}


.dropzone {
    border: 4px dashed rgba(52, 152, 219, 0.4);
    background: none;
    .dz-message {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 20px;
        margin: 8em 0;
        span {
            color: rgba(99, 107, 111, 0.6);
        }
    }
    .dz-preview.dz-image-preview {
        background: none;
    }
}
.upload-btn {
    position: relative;
    background-color: rgba(52, 152, 219, 1);
    color: white;
    padding: 10px 40px;
    border: none;
    top: 0;
    margin-top: 20px;
    box-shadow:0 3px rgba(41, 128, 185, 1.0);
    transition: all 0.3s ease-in-out;
}
.upload-btn:hover, .upload-btn:focus {
    outline: none;
    color: white;
    top: 3px;
    box-shadow:0 0px rgba(41, 128, 185, 1.0);
}
